export const colors = {
	mainDarkBg: "#1a202c",
	mainLightBg: "#C3F9E6",
	tealText: "#C3F9E6",
	tealBg: "#003D2F",
	darkerGreen: "#26D852",
	grey: "#383838",
	textColorSalmon: "#e60067",
	textColorSecondaryDark: "#ff0a78",
	buttonBackgroundLight: "rgba(255, 88, 116, 0.1)",
	yellow: "#FFD600",
	blue: "rgb(123, 0, 255)",
	infoBoxBackground: "rgba(40, 162, 255, 0.1)",
	warningBoxBackground: "rgba(255, 214, 0, 0.1)",
	successBoxBackground: "rgba(38, 216, 82, 0.1)",
};
