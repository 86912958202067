import React, { useState } from "react";
import Logo from "./Atoms/Logo";
import { NavLink } from "./Atoms/NavLink";
import ThemeToggleButton from "./Atoms/ThemeToggleButton";
import { TfiLayoutGrid3Alt } from "react-icons/tfi";
import { SlVolume2, SlVolumeOff } from "react-icons/sl";

import { useSoundFX } from "../../context/app-context";

const Header = () => {
	const [navbarOpen, setNavbarOpen] = useState(false);
	const { handleSFXSwitch, prefersMuted } = useSoundFX();

	return (
		<header className="fixed top-0 z-30 w-full px-2 sm:px-4 shadow-sm backdrop-blur-3xl md:backdrop-blur-lg">
			<nav aria-label="page-navigation">
				<div className="container max-w-4xl px-4 mx-auto flex items-center justify-between">
					<Logo />
					<div className="hidden md:flex md:flex-row items-center">
						<NavLink />
						<button className="ml-8" onClick={handleSFXSwitch} aria-label="toggle-soundFX">
							{prefersMuted === "true" ? <SlVolumeOff /> : <SlVolume2 />}
						</button>
						<div className="ml-8">
							<ThemeToggleButton />
						</div>
					</div>
					<button
						className="block md:hidden cursor-pointer ml-auto relative w-12 h-12 p-4"
						onClick={() => setNavbarOpen(!navbarOpen)}
					>
						<TfiLayoutGrid3Alt
							aria-label="toggle-navigation-menu"
							className="text-[color:var(--secondary)] text-xl"
						/>
					</button>
				</div>
				{navbarOpen ? (
					<div className="flex items-start justify-center w-full h-screen mt-20">
						<div className="flex flex-col text-2xl space-y-8">
							<div className="flex flex-row space-x-8 mb-4">
								<button className="ml-8" onClick={handleSFXSwitch} aria-label="toggle-soundFX">
									{prefersMuted === "false" ? <SlVolumeOff /> : <SlVolume2 />}
								</button>
								<div>
									<ThemeToggleButton />
								</div>
							</div>
							<NavLink onClickAction={() => navbarOpen && setNavbarOpen(false)} />
						</div>
					</div>
				) : null}
			</nav>
		</header>
	);
};

export default Header;
