import React from "react";
import { windowOpen } from "../../../utility/helpers/windowOpen";

import popSFX from "../../../assets/sounds/pop.mp3";
import useSound from "use-sound";
import { FaGithubAlt, FaDev, FaPaperPlane, FaLinkedin } from "react-icons/fa";
import useIsClient from "../../../utility/hooks/isClient";
import { useSoundFX } from "../../../context/app-context";

export const Socials = () => {
	const [play] = useSound(popSFX, { volume: 0.25 });
	const { prefersMuted } = useSoundFX();

	const { isClient, key } = useIsClient();

	function handlePlay() {
		prefersMuted === "false" && play();
	}

	if (!isClient) return null;

	return (
		<div className="flex flex-row text-2xl space-x-6 text-black dark:text-white" key={key}>
			<a
				className=" hover:text-yellow transition duration-500 ease-in-out"
				role="button"
				aria-label="github_Link"
				target="_blank"
				onMouseEnter={handlePlay}
				onClick={() => windowOpen("https://github.com/niemax")}
			>
				<FaGithubAlt />
			</a>

			<a
				className="hover:text-yellow transition duration-500 ease-in-out"
				role="button"
				aria-label="dev.to-link"
				target="_blank"
				onMouseEnter={handlePlay}
				onClick={() => windowOpen("https://dev.to/axel_nieminen_072275fab50")}
			>
				<FaDev />
			</a>

			<a
				className="hover:text-yellow transition duration-500 ease-in-out"
				role="button"
				aria-label="linkedin-link"
				target="_blank"
				onMouseEnter={handlePlay}
				onClick={() => windowOpen("https://www.linkedin.com/in/axel-nieminen-06a580196/")}
			>
				<FaLinkedin />
			</a>

			<a
				className="hover:text-yellow transition duration-500 ease-in-out"
				role="button"
				target="_blank"
				aria-label="mail-link"
				onMouseEnter={handlePlay}
				onClick={() => windowOpen("mailto:axel.nieminen@mac.com")}
			>
				<FaPaperPlane />
			</a>
		</div>
	);
};
