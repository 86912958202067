import React from "react";
import Layout from "./src/components/UI/layout";
import SoundFXProvider from "./src/context/app-context";

export const wrapPageElement = ({ element }) => {
  return (
    <SoundFXProvider>
      <Layout>{element}</Layout>
    </SoundFXProvider>
  );
};
