import React, { useEffect, useState } from "react";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import useSound from "use-sound";

import switchSFX from "../../../assets/sounds/switch.mp3";
import { useSoundFX } from "../../../context/app-context";
import { colors } from "../../../theme/foundations/colors";
import useIsClient from "../../../utility/hooks/isClient";

const ThemeToggleButton = () => {
	const [play] = useSound(switchSFX, { volume: 0.35 });
	const { prefersMuted } = useSoundFX();

	const [theme, setTheme] = useState(undefined);
	useEffect(() => {
		// @ts-ignore
		setTheme(window.__theme);
		// @ts-ignore
		window.__onThemeChange = () => {
			// @ts-ignore
			setTheme(window.__theme);
		};
	});

	function toggleTheme() {
		// @ts-ignore
		window.__setPreferredTheme(theme === `light` ? "dark" : "light");
	}

	const { isClient, key } = useIsClient();

	if (!isClient) return null;

	return (
		<DarkModeSwitch
			key={key}
			moonColor="#a4a4a4"
			sunColor={colors.yellow}
			checked={theme === "dark"}
			onChange={() => {
				toggleTheme();
				prefersMuted === "false" && play();
			}}
			size={36}
		/>
	);
};

export default ThemeToggleButton;
