import React from "react";
import Logo from "./Atoms/Logo";
import { Socials } from "./Atoms/Socials";

export const Footer = () => {
	return (
		<footer
			aria-label="footer"
			className="relative bottom-0 w-full bg-[color:var(--codeBlock)] shadow px-4 pb-4"
		>
			<div>
				<div className="max-w-4xl mx-auto px-4">
					<div className="flex flex-col md:flex-row justify-between items-center">
						<div>
							<Logo />
						</div>
						<div className="flex flex-col items-center md:flex-row space-x-4 space-y-4 md:space-y-0">
							<p className="text-sm">&copy; {new Date().getFullYear()} Axel Nieminen</p>
							<Socials />
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};
