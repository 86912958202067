import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

type AllNavlinksJson = {
	allNavlinksJson: {
		edges: {
			node: {
				ariaLabel: string;
				name: string;
				path: string;
			};
		}[];
	};
};

interface NavLinkProps {
	onClickAction?: () => void;
}

export const NavLink = ({ onClickAction }: NavLinkProps) => {
	const { allNavlinksJson } = useStaticQuery<AllNavlinksJson>(
		graphql`
			query {
				allNavlinksJson {
					edges {
						node {
							ariaLabel
							name
							path
						}
					}
				}
			}
		`
	);

	return (
		<>
			{allNavlinksJson.edges?.map(({ node }) => (
				<Link
					onClick={onClickAction}
					key={node.name}
					to={node.path}
					aria-label={`navigate-to-page-${node.name}`}
					activeStyle={{ color: "var(--secondary)", textDecoration: "underline" }}
					className="link link-underline link-underline-black"
					style={{
						fontWeight: 400,
						marginLeft: "2.25rem",
					}}
				>
					{node.name}
				</Link>
			))}
		</>
	);
};
