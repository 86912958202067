import React, { createContext, useContext, useEffect, useState } from "react";
import useSound from "use-sound";

import unmute from "../assets/sounds/unmute.mp3";
import mute from "../assets/sounds/mute.mp3";

export const SoundFXContext = createContext({} as any);

const SoundFXProvider = ({ children }: any) => {
	const [prefersMuted, setPrefersMuted] = useState<string>();
	const [play] = useSound(prefersMuted === "true" ? unmute : mute, { volume: 0.2 });

	function getSoundFXPreference() {
		try {
			const item = localStorage.getItem("prefersMuted");

			setPrefersMuted(item ?? "false");
		} catch (error) {
			throw new Error("Something went wrong determining user SFX preferance.");
		}
	}

	function handleSoundFXPreferenceSwitch() {
		if (prefersMuted === "true") {
			localStorage.setItem("prefersMuted", "false");
			setPrefersMuted("false");
		} else {
			localStorage.setItem("prefersMuted", "true");
			setPrefersMuted("true");
		}

		play();
	}

	useEffect(() => {
		getSoundFXPreference();
	}, []);

	return (
		<SoundFXContext.Provider
			value={{ prefersMuted: prefersMuted, handleSFXSwitch: handleSoundFXPreferenceSwitch }}
		>
			{children}
		</SoundFXContext.Provider>
	);
};

export const useSoundFX = () => useContext(SoundFXContext);

export default SoundFXProvider;
