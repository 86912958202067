exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-posts-accessibility-in-the-modern-web-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/posts/accessibility-in-the-modern-web.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-posts-accessibility-in-the-modern-web-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-posts-javascript-best-practices-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/posts/javascript-best-practices.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-posts-javascript-best-practices-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-posts-react-table-with-react-query-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/posts/react-table-with-react-query.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-posts-react-table-with-react-query-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-snippets-base-api-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/snippets/baseApi.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-snippets-base-api-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-snippets-use-close-with-esc-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/snippets/use-close-with-esc.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-snippets-use-close-with-esc-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-snippets-use-debounce-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/snippets/use-debounce.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-snippets-use-debounce-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-skills-index-tsx": () => import("./../../../src/pages/skills/index.tsx" /* webpackChunkName: "component---src-pages-skills-index-tsx" */),
  "component---src-pages-snippets-index-tsx": () => import("./../../../src/pages/snippets/index.tsx" /* webpackChunkName: "component---src-pages-snippets-index-tsx" */),
  "component---src-pages-snippets-mdx-frontmatter-slug-tsx-content-file-path-src-posts-accessibility-in-the-modern-web-mdx": () => import("./../../../src/pages/snippets/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/posts/accessibility-in-the-modern-web.mdx" /* webpackChunkName: "component---src-pages-snippets-mdx-frontmatter-slug-tsx-content-file-path-src-posts-accessibility-in-the-modern-web-mdx" */),
  "component---src-pages-snippets-mdx-frontmatter-slug-tsx-content-file-path-src-posts-javascript-best-practices-mdx": () => import("./../../../src/pages/snippets/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/posts/javascript-best-practices.mdx" /* webpackChunkName: "component---src-pages-snippets-mdx-frontmatter-slug-tsx-content-file-path-src-posts-javascript-best-practices-mdx" */),
  "component---src-pages-snippets-mdx-frontmatter-slug-tsx-content-file-path-src-posts-react-table-with-react-query-mdx": () => import("./../../../src/pages/snippets/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/posts/react-table-with-react-query.mdx" /* webpackChunkName: "component---src-pages-snippets-mdx-frontmatter-slug-tsx-content-file-path-src-posts-react-table-with-react-query-mdx" */),
  "component---src-pages-snippets-mdx-frontmatter-slug-tsx-content-file-path-src-snippets-base-api-mdx": () => import("./../../../src/pages/snippets/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/snippets/baseApi.mdx" /* webpackChunkName: "component---src-pages-snippets-mdx-frontmatter-slug-tsx-content-file-path-src-snippets-base-api-mdx" */),
  "component---src-pages-snippets-mdx-frontmatter-slug-tsx-content-file-path-src-snippets-use-close-with-esc-mdx": () => import("./../../../src/pages/snippets/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/snippets/use-close-with-esc.mdx" /* webpackChunkName: "component---src-pages-snippets-mdx-frontmatter-slug-tsx-content-file-path-src-snippets-use-close-with-esc-mdx" */),
  "component---src-pages-snippets-mdx-frontmatter-slug-tsx-content-file-path-src-snippets-use-debounce-mdx": () => import("./../../../src/pages/snippets/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/snippets/use-debounce.mdx" /* webpackChunkName: "component---src-pages-snippets-mdx-frontmatter-slug-tsx-content-file-path-src-snippets-use-debounce-mdx" */),
  "component---src-pages-uses-index-tsx": () => import("./../../../src/pages/uses/index.tsx" /* webpackChunkName: "component---src-pages-uses-index-tsx" */),
  "component---src-posts-accessibility-in-the-modern-web-mdx": () => import("./../../../src/posts/accessibility-in-the-modern-web.mdx" /* webpackChunkName: "component---src-posts-accessibility-in-the-modern-web-mdx" */),
  "component---src-posts-javascript-best-practices-mdx": () => import("./../../../src/posts/javascript-best-practices.mdx" /* webpackChunkName: "component---src-posts-javascript-best-practices-mdx" */),
  "component---src-posts-react-table-with-react-query-mdx": () => import("./../../../src/posts/react-table-with-react-query.mdx" /* webpackChunkName: "component---src-posts-react-table-with-react-query-mdx" */),
  "component---src-snippets-base-api-mdx": () => import("./../../../src/snippets/baseApi.mdx" /* webpackChunkName: "component---src-snippets-base-api-mdx" */),
  "component---src-snippets-use-close-with-esc-mdx": () => import("./../../../src/snippets/use-close-with-esc.mdx" /* webpackChunkName: "component---src-snippets-use-close-with-esc-mdx" */),
  "component---src-snippets-use-debounce-mdx": () => import("./../../../src/snippets/use-debounce.mdx" /* webpackChunkName: "component---src-snippets-use-debounce-mdx" */)
}

