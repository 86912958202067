import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

function Logo() {
	return (
		<Link to="/" aria-label="home">
			<StaticImage
				src="https://res.cloudinary.com/dnqfusics/image/upload/v1681814076/samples/niemax.me/a-removebg-preview_sbb5cd.png"
				alt="niemax_logo"
				placeholder="blurred"
				quality={100}
				width={120}
				height={90}
			/>
		</Link>
	);
}

export default Logo;
