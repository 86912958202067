import React, { ReactNode } from "react";
import Header from "./header";
import { Footer } from "./footer";
import useIsClient from "../../utility/hooks/isClient";

interface ILayoutProps {
	children: ReactNode;
}

const Layout = ({ children }: ILayoutProps) => {
	const { isClient } = useIsClient();

	if (!isClient) return null;

	return (
		<>
			<Header />
			<main className="bg-mainLightBg dark:bg-mainDarkBg">{children}</main>
			<Footer />
		</>
	);
};

export default Layout;
